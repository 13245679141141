@use "sass:math";

/*
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
 */

.row {
	display: block;
	@include clearfix;
	margin: -$grid-gutter-base;

	@include mq($from: tablet) {
		margin: -$grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		margin: -$grid-gutter-desktop;
	}

	@include mq($from: wide) {
		margin: -$grid-gutter-wide;
	}

	&.add-vertical-padding {
		@include mq($until: tablet) {
			padding-top: $grid-gutter-base;
			padding-bottom: $grid-gutter-base;
		}
	}

	&.row--lowpadding {
		margin:-2px;
	}
}

.col-1-2,
.col-2-2,
.col-1-3,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4 {
	display: block;
	width: 100%;
	padding: $grid-gutter-base;
	float: left;

	.row--reverse & {
		float: right;
	}

	@include mq($from: tablet) {
		padding: $grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		padding: $grid-gutter-desktop;
	}

	@include mq($from: wide) {
		padding: $grid-gutter-wide;
	}

	.row--lowpadding & {
		padding: 2px;
	}

	&.center {
		float: none;
		margin: 0 auto;
	}

	
}

.col-1-2 {
	.row--smallsize & {
		width: percentage( math.div(1,2) );
	}

	@include mq($from: tablet) {
		width: percentage( math.div(1,2) );
	}
}

.col-1-3 {
	.row--smallsize & {
		width: percentage( math.div(1,3) );
	}
	@include mq($from: tablet) {
		width: percentage( math.div(1,3) );
	}
}

.col-2-3 {
	.row--smallsize & {
		width: percentage( math.div(2,3) );
	}
	@include mq($from: tablet) {
		width: percentage( math.div(2,3) );
	}
}

.col-1-4 {
	.row--smallsize & {
		width: percentage( math.div(1,4) );
	}
	@include mq($from: tablet) {
		width: percentage( math.div(1,4) );
	}
}

.col-2-4 {
	.row--smallsize & {
		width: percentage( math.div(2,4) );
	}
	@include mq($from: tablet) {
		width: percentage( math.div(2,4) );
	}
}

.col-3-4 {

	.row--smallsize & {
		width: percentage( math.div(3,4) );
	}
	@include mq($from: tablet) {
		width: percentage( math.div(3,4) );
	}
}
