.hidden { display: none !important;  }

.clear { clear: both; }

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

.img-responsive { width: 100%; height: auto; }
.relative { position: relative; }

.absolute-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.to-tablet,
.until-tablet {
	@include mq($from: tablet) {
		display: none;
	}
}

.to-desktop,
.until-desktop {
	@include mq($from: desktop) {
		display: none;
	}
}

.to-wide,
.until-wide {
	@include mq($from: wide) {
		display: none;
	}
}

.to-xwide,
.until-xwide {
	@include mq($from: xwide) {
		display: none;
	}
}

.from-tablet {
	@include mq($until: tablet) {
		display: none;
	}
}

.from-desktop {
	@include mq($until: desktop) {
		display: none;
	}
}

.from-wide {
	@include mq($until: wide) {
		display: none;
	}
}

.from-xwide {
	@include mq($until: xwide) {
		display: none;
	}
}

%better-icon,
%icon {
	font-family: $font-icon;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#__bs_notify__ {
	width: 170px !important;
	right: 50% !important;
	padding: 10px !important;
	margin-right: -85px !important;
	background-color: rgba(#000, 0.3) !important;
	border-radius: 0 !important;
	font-size: 12px !important;
}