@font-face {
    font-family: 'GothamRndMedium';
    src: url('../fonts/GothamRndMedium.eot');
    src: url('../fonts/GothamRndMedium.eot') format('embedded-opentype'),
         url('../fonts/GothamRndMedium.woff2') format('woff2'),
         url('../fonts/GothamRndMedium.woff') format('woff'),
         url('../fonts/GothamRndMedium.ttf') format('truetype'),
         url('../fonts/GothamRndMedium.svg#GothamRndMedium') format('svg');
         font-weight: normal;
         font-style: normal;
}

@font-face {
    font-family: 'GothamRndBook';
    src: url('../fonts/GothamRndBook.eot');
    src: url('../fonts/GothamRndBook.eot') format('embedded-opentype'),
         url('../fonts/GothamRndBook.woff2') format('woff2'),
         url('../fonts/GothamRndBook.woff') format('woff'),
         url('../fonts/GothamRndBook.ttf') format('truetype'),
         url('../fonts/GothamRndBook.svg#GothamRndBook') format('svg');
         font-weight: normal;
         font-style: normal;
}
@font-face {
    font-family: 'GothamRndLight';
    src: url('../fonts/GothamRndLight.eot');
    src: url('../fonts/GothamRndLight.eot') format('embedded-opentype'),
         url('../fonts/GothamRndLight.woff2') format('woff2'),
         url('../fonts/GothamRndLight.woff') format('woff'),
         url('../fonts/GothamRndLight.ttf') format('truetype'),
         url('../fonts/GothamRndLight.svg#GothamRndLight') format('svg');
         font-weight: normal;
         font-style: normal;
}
@font-face {
    font-family: 'GothamRndBold';
    src: url('../fonts/GothamRndBold.eot');
    src: url('../fonts/GothamRndBold.eot') format('embedded-opentype'),
         url('../fonts/GothamRndBold.woff2') format('woff2'),
         url('../fonts/GothamRndBold.woff') format('woff'),
         url('../fonts/GothamRndBold.ttf') format('truetype'),
         url('../fonts/GothamRndBold.svg#GothamRndBold') format('svg');
         font-weight: normal;
         font-style: normal;
}

@font-face {
    font-family: 'Opticon';
    src:  url('../fonts/Opticon.eot?aj5ade');
    src:  url('../fonts/Opticon.eot?aj5ade#iefix') format('embedded-opentype'),
    url('../fonts/Opticon.ttf?aj5ade') format('truetype'),
    url('../fonts/Opticon.woff?aj5ade') format('woff'),
    url('../fonts/Opticon.svg?aj5ade#Opticon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Opticon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cog:before {
    content: "\e910";
}
.icon-gear:before {
    content: "\e910";
}
.icon-preferences:before {
    content: "\e910";
}
.icon-settings:before {
    content: "\e910";
}
.icon-generate:before {
    content: "\e910";
}
.icon-control:before {
    content: "\e910";
}
.icon-options:before {
    content: "\e910";
}
.icon-aid-kit:before {
    content: "\e911";
}
.icon-health:before {
    content: "\e911";
}
.icon-medicine:before {
    content: "\e911";
}
.icon-medical:before {
    content: "\e911";
}
.icon-close:before {
    content: "\e900";
}
.icon-menu:before {
    content: "\e901";
}
.icon-instagram:before {
    content: "\e90f";
}
.icon-youtube:before {
    content: "\e902";
}
.icon-twitter:before {
    content: "\e903";
}
.icon-facebook:before {
    content: "\e904";
}
.icon-calendar:before {
    content: "\e905";
}
.icon-testing:before {
    content: "\e906";
}
.icon-training:before {
    content: "\e907";
}
.icon-run:before {
    content: "\e908";
}
.icon-alerte:before {
    content: "\e909";
}
.icon-admin:before {
    content: "\e90a";
}
.icon-mail:before {
    content: "\e90b";
}
.icon-phone:before {
    content: "\e90c";
}
.icon-google-plus:before {
    content: "\e90d";
}
.icon-arrow:before {
    content: "\e90e";
}

