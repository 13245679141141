html { font-size: 62.5%; }

$font-base: GothamRndLight, sans-serif;
$font-light: GothamRndLight, sans-serif;
$font-medium: GothamRndMedium, sans-serif;
$font-book: GothamRndBook, sans-serif;
$font-bold: GothamRndBold, sans-serif;
$font-alt:  Georgia, serif;
$font-icon: 'mcube';
$image-path: '/images/';

$color-1: #393e51;
$color-2: #23b7e5;
$color-3: #fff;
$color-4: #f2f2f2;
$color-5: #2f3340; 
$color-6: #ed145b;

$color-bg:          #fff;
$color-text:        $color-1;
$color-text-light:  #fff;

$color-cancel: #bc2121;

$color-link: red;
$color-link-hover: blue;

$color-border: #ddd;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;

$grid-gutter-base:    10px;
$grid-gutter-tablet:  10px;
$grid-gutter-desktop: 15px;
$grid-gutter-wide:    15px;