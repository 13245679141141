html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: $color-bg;

	@include font-size(14);
	font-family: $font-base;
	color: $color-text;

	&.noscroll {
		overflow: hidden;
	}
}

a {
	color: $color-text;
}

.clear {
	clear: both;
}

/*
##            ###     ##    ##    #######   ##     ##  ########
##           ## ##     ##  ##    ##     ##  ##     ##     ##
##          ##   ##     ####     ##     ##  ##     ##     ##
##         ##     ##     ##      ##     ##  ##     ##     ##
##         #########     ##      ##     ##  ##     ##     ##
##         ##     ##     ##      ##     ##  ##     ##     ##
########   ##     ##     ##       #######    #######      ##
*/


.wrapper {
	width: 100%;
	max-width: 460px;
	margin: 0 auto;
	padding: 20px;
	position: relative;

	@include mq($from: tablet) {
		max-width: 768px;
	}

	@include mq($from: desktop) {
		max-width: 1024px;
	}

	@include mq($from: wide) {
		max-width: 1200px;
	}

	@include mq($from: xwide) {
		max-width: 1400px;
	}
}

.wrapper--alt {
	background-color: $color-4;
}

.wrapper--header {
	position: absolute;
	z-index: 1;
	bottom: 30px;
	right: 20px;
	text-align: right;

	@include mq($from: tablet) {
		right: 40px;
		bottom: 50px;
		width: 60%;
	}

	@include mq($from: desktop) {
		right: 50px;
		bottom: 60px;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 15%;
		height: 1px;
		background-color: $color-3;
		bottom: -5px;
		right: 0;

		@include mq($from: tablet) {
			bottom: -20px;
		}
	}
}

.padder {
	position: relative;
	@include mq($from: tablet) {
		padding: 30px 0;
	}

	@include mq($from: wide) {
		padding: 50px 0;
	}
}

.inner-border {
	border: 1px solid $color-2;
	padding: 20px;
}


/*
########      ###     ##    ##   ##    ##   ########   ########
##     ##    ## ##    ###   ##   ###   ##   ##         ##     ##
##     ##   ##   ##   ####  ##   ####  ##   ##         ##     ##
########   ##     ##  ## ## ##   ## ## ##   ######     ########
##     ##  #########  ##  ####   ##  ####   ##         ##   ##
##     ##  ##     ##  ##   ###   ##   ###   ##         ##    ##
########   ##     ##  ##    ##   ##    ##   ########   ##     ##
 */

.banner {
	width: 100%;
	height: 200px;
	background: transparent url('#{$image-path}top/home.jpg') 0 0 no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;

	@include mq($from: tablet) {
		width: 100%;
		height: 250px;
	}
	@include mq($from: desktop) {
		width: 100%;
		height: 300px;
	}

	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		right: 0;
		bottom: 0;
		background: linear-gradient(to bottom right, transparent 0%, transparent 30%, rgba($color-1, 0.8) 100%);
	}

	.home & {
		height: 300px;

		@include mq($from: tablet) {
			height: 400px;
		}
		@include mq($from: desktop, $and: '(min-height: 700px)') {
			height: 600px;
		}
	}

	.optitrainer & {
		background-image: url('#{$image-path}top/optitrainer.jpg');
	}

	.fonctionnalite & {
		background-image: url('#{$image-path}top/fonctionnalite.jpg');
	}

	.tarif & {
		background-image: url('#{$image-path}top/tarif.jpg');
	}

	.contact & {
		background-image: url('#{$image-path}top/contact.jpg');
	}

	// .optitrainer & {background-image: url('#{$image-path}top/optitrainer.jpg');}
	// .optitrainer & {background-image: url('#{$image-path}top/optitrainer.jpg');}
	// .optitrainer & {background-image: url('#{$image-path}top/optitrainer.jpg');}
}


/*
########   ##    ##   ########    #######
   ##       ##  ##    ##     ##  ##     ##
   ##        ####     ##     ##  ##     ##
   ##         ##      ########   ##     ##
   ##         ##      ##         ##     ##
   ##         ##      ##         ##     ##
   ##         ##      ##          #######
*/


.title-lev0 {
	color: $color-3;
	font-size: 1.4em;
	font-family: $font-light;

	@include mq($from: tablet) {
		font-size: 1.8em;
	}

	@include mq($from: desktop) {
		font-size: 2.2em;
	}

	span {
		display: block;
		font-size: 1em;
	}

	.strong {
		font-family: $font-medium;
		color: $color-3;
	}

	.blue {
		color: $color-2;
	}
}

.title-lev1 {
	font-family: $font-medium;
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 30px;
	font-size: 1.2em;


	@include mq($from: desktop) {
		font-size: 1.4em;
	}

	@include mq($from: wide) {
		font-size: 1.5em;
	}

	&.title-lev1--center {
		text-align: center;

		&:after {
			content: "";
			display: block;
			width: 10%;
			height: 1.5px;
			background-color: $color-1;
			margin: 0px auto;
			margin-top: 15px;
		}

		@include mq($from: tablet) {
			margin-bottom: 50px;
			font-size: 1.3em;
			letter-spacing: 0.8px;
		}

		@include mq($from: desktop) {
			margin-bottom: 20px;
		}
	}
}

.title-lev2 {
	display: block;
	text-transform: uppercase;
	color: $color-2;
	font-size: 1.3em;
	margin-bottom: 1em;
	font-family: $font-book;

	@include mq($from: tablet) {
		font-size: 1.3em;
		margin-top: 10px;
	}

	@include mq($from: desktop) {
		width: 65%;
		font-size: 1.4em;
	}

	&.title-lev2--full {
		width: 100%;

		&:after {
			content: "";
			display: block;
			width: 20%;
			height: 1px;
			background-color: $color-2;
			margin: 0px auto;
			bottom: -20px;
			margin-top: 10px !important;
		}

		@include mq($from: desktop) {
			font-size: 1.5em;
		}
	}
}

.strong {
	color: $color-6;
	font-family: $font-medium;
	font-size: 1.5em;
	@include mq($from: tablet) {
		font-size: 1.7em;
		// margin-top: 40px;
		// margin-bottom: 40px;
	}
}

.small {
	font-size: 0.5em;
}


/*
##     ##  ########   ####  ##          ######
##     ##     ##       ##   ##         ##    ##
##     ##     ##       ##   ##         ##
##     ##     ##       ##   ##          ######
##     ##     ##       ##   ##               ##
##     ##     ##       ##   ##         ##    ##
 #######      ##      ####  ########    ######
 */

.round {
	border: 1px solid $color-2;
	border-radius: 50%;
	float: right;
	width: 70px;
	height: 70px;

	@include mq($from: tablet) {
		width: 80px;
		height: 80px;

	}
}


.disc {
	width: 50px;
	height: 50px;
	background-color: $color-1;
	border-radius: 50%;
	@include mq($from: tablet) {
		width: 60px;
		height: 60px;
	}

	& .icon {
		font-size: 2.5em;
		line-height: 50px;
		color: white;
		@include mq($from: tablet) {
			line-height: 60px;
		}

		&.icon-settings, &.icon-health {
			font-size: 1.5em;
		}
	}

}

.number {
	font-size: 1.8em;
	text-align: center;
	display: block;
	color: $color-2;
	line-height: 80px;
	font-family: $font-book;

	@include mq($from: tablet) {
		font-size: 2.2em;
	}
}

.icon {
	font-size: 3.5em;
	display: block;
	color: $color-2;
	text-align: center;

	&.icon--big {
		line-height: 70px;
		@include mq($from: tablet) {
			line-height: 80px;
		}
	}
}


.liseret {
	margin-bottom: 20px;

	&:after {
		content: "";
		display: block;
		width: 30px;
		height: 1px;
		background-color: $color-2;
		margin-top: 10px;
		right: 20px;
	}
}

.button {
	@include clearfix;
	color: $color-3;
	text-transform: uppercase;
	background-color: $color-2;
	padding: 15px 20px;
	border-radius: 3px;
	display: inline-block;
	width: 230px;
	border: 2px $color-2 solid;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: color, background-color;
	transition-property: color, background-color;


	@include mq($from: desktop) {
		width: 230px;
	}
}

.button:hover {
	border: 2px $color-2 solid;
	background-color: transparent;
	color: $color-2;
}


/*
##     ##  ########      ###     ########   ########   ########
##     ##  ##           ## ##    ##     ##  ##         ##     ##
##     ##  ##          ##   ##   ##     ##  ##         ##     ##
#########  ######     ##     ##  ##     ##  ######     ########
##     ##  ##         #########  ##     ##  ##         ##   ##
##     ##  ##         ##     ##  ##     ##  ##         ##    ##
##     ##  ########   ##     ##  ########   ########   ##     ##
*/

.menu {
	@include clearfix;
}

.logo {
	float: left;
	display: block;
	width: 200px;
	height: 40px;
	background: transparent url('#{$image-path}optitrainer-logo.png') 0 0 no-repeat;
	background-size: 200px 40px;

	@include hidpi {
		background-image: url('#{$image-path}optitrainer-logo@2x.png');
	}

	@include mq($from: desktop) {
		width: 300px;
		height: 60px;
		background-size: 300px 60px;
	}

	span {
		display: block;
		text-indent: -9999px;
	}
}


/*
##    ##      ###     ##     ##
###   ##     ## ##    ##     ##
####  ##    ##   ##   ##     ##
## ## ##   ##     ##  ##     ##
##  ####   #########   ##   ##
##   ###   ##     ##    ## ##
##    ##   ##     ##     ###
*/

.mobile {
	@include mq($until: tablet) {
		width: 25px;
		height: 25px;
		background: transparent url('#{$image-path}menu.png') 0 0 no-repeat;
		background-size: 25px 25px;
		margin-top: 5px;
		float: right;
		cursor: pointer;
	}

	@include mq($from: tablet) {
		display: none;
	}
}

.nav-main__close {
	@include mq($until: tablet) {
		display: block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		position: absolute;
		top: 25px;
		left: 20px;
		z-index: 6;


		@include font-size(32);
		text-align: center;
		color: $color-1;

		transition: background 0.3s ease-in-out, color 0.3s ease-out;

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active {
			color: $color-2;

		}
	}

	@include mq($from: tablet) {
		display: none;
	}
}

.nav-main {
	@include mq($until: tablet) {
		display: block;
		padding: 20px;
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		opacity: 1;

		background-color: $color-4;

		transform: translate(100%, 0);
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

		.nav-open & {
			transform: translate(0, 0);
			opacity: 1;
		}

		.menu-container {
			margin-top: 40px;
			margin-left: 0px;
		}

		.menu-item {
			text-transform: uppercase;
		}

		.item-link {
			display: block;
			font-size: 1.8em;
			color: $color-1;
			padding: 20px 0;
			position: relative;

			&:after {
				height: 1px;
				content: "";
				width: calc(100% - 20px);
				background-color: #A4A4A4;
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
		.item-link:hover {
			color: $color-2;
		}
	}

	.icon-arrow {

		@include mq($until: tablet) {
			font-size: 1.5em;
			color: $color-1;
			display: inline-block;
			right: 20px;
			top: 50%;
			transform: translate(0, -50%);
			position: absolute;
		}
		display: none;
	}


	@include mq($from: tablet) {

		.menu-hover {
			display: inline-block;
			vertical-align: middle;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			box-shadow: 0 0 1px rgba(0, 0, 0, 0);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-moz-osx-font-smoothing: grayscale;
			position: relative;
			overflow: hidden;
		}

		.menu-hover:before {
			content: "";
			position: absolute;
			z-index: -1;
			left: 50%;
			right: 50%;
			bottom: 0;
			background: $color-2;
			height: 3px;
			-webkit-transition-property: left, right;
			transition-property: left, right;
			-webkit-transition-duration: 0.2s;
			transition-duration: 0.2s;
			-webkit-transition-timing-function: ease-out;
			transition-timing-function: ease-out;
		}

		.menu-hover:hover:before, .menu-hover:focus:before, .menu-hover:active:before {
			left: 0;
			right: 0;
		}

		.menu-container {
			float: right;
			padding-top: 10px;
			display: block;
		}

		.menu-item {
			display: inline-block;

			font-size: 0.8em;
			padding: 0 8px;
			font-family: $font-medium;

			@include mq($from: desktop) {
				padding: 15px;
			}

			.item-link {
				color: $color-1;
				text-transform: uppercase;

				&.current {
					color: $color-2;
				}
			}

		}


		.menu-item:last-child {
			background-color: $color-2;

			.item-link {
				color: $color-3;

				&:hover:after {
					display: none;
				}
			}
		}

		.connect-button {
			display: inline-block;
			vertical-align: middle;
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			box-shadow: 0 0 1px rgba(0, 0, 0, 0);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			-moz-osx-font-smoothing: grayscale;
			overflow: hidden;
			-webkit-transition-duration: 0.3s;
			transition-duration: 0.3s;
			-webkit-transition-property: color, background-color;
			transition-property: color, background-color;
			border-radius: 2px;
			padding: 12px 20px;
		}
		.connect-button:hover, .connect-button:focus, .connect-button:active {
			background-color: $color-1;
			color: white;
		}
	}

	@include mq($from: desktop) {
		.menu-item {
			font-size: 0.9em;
		}
	}
}


/*
##     ##   #######   ##     ##  ########
##     ##  ##     ##  ###   ###  ##
##     ##  ##     ##  #### ####  ##
#########  ##     ##  ## ### ##  ######
##     ##  ##     ##  ##     ##  ##
##     ##  ##     ##  ##     ##  ##
##     ##   #######   ##     ##  ########
 */


.feature {
	color: $color-2;
	font-family: $font-book;
	display: block;
	text-transform: uppercase;
	font-size: 1em;
	width: 55%;
	line-height: 35px;
	position: relative;
	padding-top: 15%;
	padding-bottom: 50px;

	@include mq($from: tablet) {
		font-size: 1.3em;
		line-height: 35px;
		padding-top: 10%;
	}

	@include mq($from: desktop) {
		width: 50%;
		font-size: 1.5em;
		line-height: 45px;
		padding-top: 20%;
	}

	@include mq($from: wide) {
		width: 40%;
	}

	&:after {
		content: "";
		display: block;
		width: 30%;
		height: 1px;
		background-color: $color-2;
		margin-top: 20px;
		position: absolute;
		right: 0;
	}
}

.appli {
	position: absolute;
	right: 0;
	bottom: -$grid-gutter-base;
	width: 40%;
	height: 100%;
	background: transparent url('#{$image-path}iphone.png') 0 0 no-repeat;
	background-size: 100% auto;

	@include mq($from: tablet) {
		bottom: -31-$grid-gutter-tablet;
		right: $grid-gutter-tablet;
	}

	@include mq($from: desktop) {
		width: 44%;
		bottom: -68-$grid-gutter-desktop;
		right: $grid-gutter-desktop;
	}

	@include mq($from: wide) {
		width: 50%;
		bottom: -40-$grid-gutter-wide;
		right: $grid-gutter-wide;
	}
}

.home-fct-liste {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
  width: 100%;

  @media(max-width: 1025px) {
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
  }

  @media(max-width: 768px) {
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: auto;
  }

	.home-fct-item {
		position: relative;
		padding: 20px 20px 20px 75px;
		font-family: $font-book;
		font-size: 1.2em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	}

	.disc {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
	}
}


/*
########    #######   ##    ##    ######    ########   ####   #######   ##    ##    ######
##         ##     ##  ###   ##   ##    ##      ##       ##   ##     ##  ###   ##   ##    ##
##         ##     ##  ####  ##   ##            ##       ##   ##     ##  ####  ##   ##
######     ##     ##  ## ## ##   ##            ##       ##   ##     ##  ## ## ##    ######
##         ##     ##  ##  ####   ##            ##       ##   ##     ##  ##  ####         ##
##         ##     ##  ##   ###   ##    ##      ##       ##   ##     ##  ##   ###   ##    ##
##          #######   ##    ##    ######       ##      ####   #######   ##    ##    ######
 */

.fonctionnalite-liste {
	margin-bottom: 50px;

	li {
		font-family: $font-book;
		margin-top: 20px;

		&:after {
			content: "";
			display: block;
			width: 20px;
			height: 1px;
			background-color: $color-2;
			margin-top: 5px;
			right: 20px;
		}
	}
}


/*
 ######     #######   ##    ##   ########      ###      ######    ########
##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##
##         ##     ##  ####  ##      ##       ##   ##   ##            ##
##         ##     ##  ## ## ##      ##      ##     ##  ##            ##
##         ##     ##  ##  ####      ##      #########  ##            ##
##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##
 ######     #######   ##    ##      ##      ##     ##   ######       ##
 */


.contact-input {
	width: 100%;
	margin-top: 5px;
	margin-bottom: 20px;
	height: 40px;
	border: 1px solid lighten($color-1, 50%);
	padding: 10px;


}

.contact-input:active, .contact-input:focus {
	border: 1px solid $color-2;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px, inset rgba(0, 0, 0, 0.1) 0px 2px 2px;
}

.error .contact-input {
	border: 1px solid red;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px, inset rgba(0, 0, 0, 0.1) 0px 2px 2px;
}

.contact-message {
	height: 200px;
}


.contact-logo {

	text-transform: uppercase;
	font-size: 1.2em;
	font-family: $font-light;


	.contact-logo-opti {
		font-family: $font-bold;
	}
}


/*
########    #######    #######   ########   ########   ########
##         ##     ##  ##     ##     ##      ##         ##     ##
##         ##     ##  ##     ##     ##      ##         ##     ##
######     ##     ##  ##     ##     ##      ######     ########
##         ##     ##  ##     ##     ##      ##         ##   ##
##         ##     ##  ##     ##     ##      ##         ##    ##
##          #######    #######      ##      ########   ##     ##
*/


.icon-mail, .icon-phone {
	float: left;
	margin-right: 5px;
	font-size: 2em;
}

.footer {
	color: $color-3;
	font-family: $font-book;
	font-size: 0.9em;


	a {
		color: $color-3;
	}

	.line-1 {
		background-color: $color-1;
	}

	.line-2 {
		background-color: $color-5;
	line-height: 25px;
	}

	.line-3 {
		background-color: $color-3;
		border-top: 1px solid $color-4;
	}

	.grid-logos {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: 1fr 1fr 1fr;
		column-gap: 5rem;
		align-items: center;
		justify-items: center;


		.client-logo {
			position: relative;
			width: 100%;
			height: 100%;
			aspect-ratio: 1/1;
			display: flex;
			align-items: center;
			justify-content: center;



			img {
				width: 100%;
				height: auto;
				object-fit: contain;
			}
		}

		@media (max-width: 765px) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr 1fr;
			column-gap: 2rem;
		}
	}


	.logo-footer {
		background: transparent url('#{$image-path}logo-footer.png') 0 0 no-repeat;
		width: 115px;
		height: 25px;
		background-size: 115px 25px;
		margin-bottom: 15px;
		margin-top: 10px;

		@include mq($until: tablet) {
			margin: 0px auto;
			float: none;
			margin-bottom: 20px;
		}
	}

  .contact-footer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	&__item {
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
	}

	@media (max-width: 768px) {
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	}
  }

	.footer-copyright {
		color: $color-3;
		font-family: $font-book;
		display: block;
		width: 100%;
		text-align: center;
		font-size: 0.9em;
		letter-spacing: 0.5px;
	}

	.social {
		width: 100%;
		text-align: center;
		float: none;

		@include mq($from: tablet) {
			width: 120px;
			float: right;
		}
	}

	.social-icon {
		display: inline-block;
		border: 1px solid $color-3;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 7px;
	}

	.social-icon {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-backface-visibility: hidden;
		-moz-osx-font-smoothing: grayscale;
		position: relative;
		overflow: hidden;
		background: transparent;
		-webkit-transition-property: color;
		transition-property: color;
		-webkit-transition-duration: 0.2s;
		transition-duration: 0.2s;
		font-size: 2em;
	}

	.social-icon:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $color-3;
		border-radius: 100%;
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: 0.2s;
		transition-duration: 0.2s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}

	.icon-footer:hover, .social-icon:focus, .social-icon:active {
		color: $color-1;
	}

	.social-icon:hover:before, .social-icon:focus:before, .social-icon:active:before {
		-webkit-transform: scale(2);
		transform: scale(2);
	}

	.icon-footer {
		line-height: 40px;
		width: 40px;
		display: block;
	}

	.col-empty {
		@include mq($until: desktop) {
			display: none;
		}
	}

	.footer-mention {
		color: $color-3;
	}

}
